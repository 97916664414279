@media only screen and (max-width: 900px) {
  .keller-williams-logo {
    display: none;
  }

  #description {
    font-size: 20px !important;
    margin-right: 15px !important;
  }

  #main-header {
    padding-bottom: 140px !important;
  }

  #left-header-content {
    display: block;
  }

  #middle-header-content {
    text-align: left;
    display: block;
    float: left;
    width: 100%;
  }
}

.App {
  text-align: center;
  min-width: 295px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#main-header {
  border-bottom: 1px solid lightgrey;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 70px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
  margin-top: 0px;
}

#main-header h1 {
  font-weight: normal;
  text-transform: uppercase;
  text-align: left;
  margin: 0px;
}

#main-header h3 {
  font-weight: normal;
  text-transform: uppercase;
  text-align: left;
  margin: 0px;
  color: darkgrey;
}

#left-header-content {
  float: left;
  vertical-align: top;
}

#middle-header-content {
  float: left;
  margin-left: 10px;
  margin-top: 10px;
}

.keller-williams-logo {
  float: right;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 60px;
  margin-top: 10px;
  margin-right: 10%;
}

#house-photo {
  width: 100%;
  margin-bottom: 20px;
}

#cyndy-photo {
  width: 200px;
  height: 300px;
  border-radius: 10px;
  display: inline-block;
}

.small-icon {
  width: 20px;
  height: 20px;
}

#email-icon {
  margin-top: 2px;
}

.info {
  display: block;
  text-align: left;
}

.info-details {
  vertical-align: top;
  margin-left: 5px;
  font-size: 18px;
}

#description {
  font-size: 25px;
  font-style: italic;
  color: #585858;
  width: 50%;
  vertical-align: top;
  margin-right: 40px;
  margin-bottom: 10px;
  display: inline-block;
}

#testimonial {
  font-style: italic;
  display: block;
  margin: 0 auto;
  padding: 35px;
  width: 500px;
  text-align: left;
  line-height: 25px;
}
